<template>
  <aside>
    <div class="header">
      <div class="item logo">
        <a class="item-logo">
          <div class="logo-image">
<!--            <img src="../assets/img/yunka_1.png">-->
            <p>云咖</p>
          </div>
        </a>
      </div>
      <template v-for="(module, index) in navMenus">
        <div class="item" :key="index"
             v-if="module.engName !== 'org'"
             @click="handleMenu(module)"
             :class="$route.name.indexOf(module.engName) >= 0? 'active': ''">
          <a
            @mouseover="hover = true"
            @mouseleave="hover = false">
            <p v-if="$route.name.indexOf(module.engName) < 0"><i :class="module.iconFront" class="icon iconfont "></i>
            </p>
            <p v-else><i :class="module.iconFront + '-fill'" class="icon iconfont "></i></p>
            <p class="item-text">{{module.name}}</p>
          </a>
        </div>
      </template>
    </div>
    <div class="tail" v-if="orgMenu !== null">
      <div class="item" @click="handleMenu(orgMenu)" :class="$route.name.indexOf(orgMenu.engName) === 0? 'org': ''">
        <p><i class="icon el-icon-setting"></i></p>
        <p class="item-text">管理舱</p>
      </div>

    </div>
  </aside>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";


  export default {
    name: '',
    components: {},
    computed: {
      ...mapGetters(['org', 'navMenus', 'orgMenu']),
    },

    mounted: function () {

    },
    data: function () {
      return {
        notifies: 100,
      }
    },
    methods: {
      ...mapActions(['accountChildMenusSet', 'accountNavMenuSet', 'accountProveAndOrg']),
      handleMenu(module) {
        this.accountChildMenusSet(module)
        if (this.$route.name.indexOf(module.engName) === -1) {
          this.$router.push({name: module.engName})
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  aside {
    position: relative;
    display: flex;
    z-index: 601;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 $side-width;
    -ms-flex: 0 0 $side-width;
    flex: 0 0 $side-width;
    min-width: 0;
    -webkit-box-orient: vertical;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    -webkit-box-shadow: inset -1px 0 0 0 #f1f1f1;
    box-shadow: inset -1px 0 0 0 #f1f1f1;

    .header {
      flex: 1;
    }

    .logo {
      height: $header-height !important;
    }

    .active {
      background-color: $theme-light-1;

      * {
        color: white;
      }
    }

    .org{
      * {
        color: $theme-color;
      }
    }

    .item {
      cursor: pointer;
      position: relative;
      height: 72px;
      text-align: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      color: #757e87;

      &-text {
        margin-top: $small-space;
        font-size: .92em;
        line-height: 1;
      }


      &-logo {
        width: 100%;
        height: 100%;

        .logo-image {
          width: 100%;
          height: $header-height - 1;
          line-height: $header-height - 1;
          padding: 0 8px;
          p{
            font-size: 20px;
            color: $theme-color;
            font-weight: 800;
            border-bottom: 1px solid #f4772112;
          }
          img{
            width: $side-width;
            height: $header-height;
          }
        }
      }

      .icon {
        font-size: 1.45em;
      }
    }

    .item:not(.active):hover {
      *{
        color: $theme-color;
      }
    }
  }
</style>
