<template>
  <div class="layout">

    <main-side v-if="!$route.meta.hiddenSide"></main-side>
    <div class="layout-main">
      <div class="layout-main-header" v-if="!$route.meta.hiddenHeader">
        <breadcrumb class="nav"></breadcrumb>
        <div class="layout-main-header-right">
          <div class="item item-margin"
               @click="$message.warning('抱歉文档功能尚未完善，正在加急编写中...')">
            <i class="iconfont icon-shiyongshuoming"></i> <span>帮助文档</span></div>
          <div class="notify item item-margin">
            <span class="icon " :class="notifies >0 ? 'number': ''"><i class="iconfont icon-remind_fill"
                                                                       :class="notifies >0 ? 'share': ''"></i> 通知
             <span class="bold" v-if="notifies >0">
                (
              <template v-if="notifies>99">
              99+
              </template>
               <template v-else-if="notifies >0">{{notifies}}</template>
               )
                </span>
            </span>
          </div>

          <el-dropdown class="item" @command="handleCommand">
              <span class="el-dropdown-link">
                 <i class="iconfont icon-lianxiren user"></i> <span>{{prove.name}} > </span>
              </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="iconfont icon-qiehuan" command="changeOrg">切换企业</el-dropdown-item>
              <el-dropdown-item icon="iconfont icon-mima" command="changePassword">修改密码</el-dropdown-item>
              <el-dropdown-item icon="iconfont icon-tuichu" command="quit">安全退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="layout-main-body" :class="!$route.meta.hiddenHeader ? 'margin-header': 'body-header'">
        <div class="main-menu" :class="collapse ?  'collapse': 'normal-menu'" v-if="!$route.meta.hidden">
          <layout-menu class="main-menu-item" :modules="menus" :show-icon="true"
                       :collapse.sync="collapse"></layout-menu>

          <p class="main-menu-opr" @click="collapse = !collapse"><i
            :class="collapse ? 'el-icon-s-unfold': 'el-icon-s-fold'"></i></p>
        </div>

        <div class="main-container" :class="$route.meta.bgClass">
          <keep-alive>
            <router-view class="main-container-body"></router-view>
          </keep-alive>
          <layout-footer></layout-footer>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import MainSide from './side'
  import Breadcrumb from '@/components/Breadcrumb'
  import LayoutMenu from '@/components/Menu'
  import {mapActions, mapGetters} from 'vuex'
  import {removeToken} from "@/utils/auth"

  export default {
    name: '',
    components: {
      MainSide,
      Breadcrumb,
      LayoutMenu
    },
    computed: {
      ...mapGetters(['org', 'prove', 'menus', 'navMenus']),
    },
    watch: {
      $route: {
        handler: function (to) {
          let clientWidth = document.body.clientWidth
          this.collapse = (to.meta.collapse || false) && (clientWidth <= 1440)
          if (to.meta.single) {
            return
          }
          let modules = this.navMenus || []
          let loop = modules.length !== 0
          let loopTime = 5
          while (loop) {
            if (loopTime < 0) {
              break
            }
            loopTime--

            for (let i = 0; i < modules.length; i++) {
              if (modules[i].engName === to.name) {
                if (to.meta.redirect) {
                  if (modules[i].modules && modules[i].modules.length > 0) {
                    this.$router.replace({name: modules[i].modules[0].engName})
                  }
                }
                break
              }
              if (to.name.indexOf(modules[i].engName) !== -1) {
                modules = modules[i].modules || []
                loop = modules.length !== 0
                break
              }
            }
          }

        },
        // 深度观察监听
        deep: true
      },
    },

    mounted: function () {
      this.loadInfo()
      let vue = this
      this.loadNotify()
      if (this.ts >= 0) {
        return
      }
      this.ts = setInterval(function () {
        vue.loadNotify()
      }, 5 * 60 * 1000)
    },
    data: function () {
      return {
        collapse: false,
        notifies: 0,
        ts: null,
        metas: [] // 路由集合
      }
    },
    methods: {
      ...mapActions(['accountChildMenusSet', 'accountNavMenuSet', 'accountProveAndOrg']),
      loadInfo() {
        let i = 0
        let navMenus = this.navMenus
        if (this.$route.name) {
          for (; i < navMenus.length; i++) {
            if (this.$route.name.indexOf(navMenus[i].engName) === 0) {
              this.handleMenu(navMenus[i])
              if (this.$route.name === navMenus[i].engName){
                if (navMenus[i].modules) {
                  this.$router.replace({name: navMenus[i].modules[0].engName})
                }
              }
              break;
            }
          }

          if (i === navMenus.length && navMenus.length > 0) {
            this.handleMenu(navMenus[0])
          }

        }

      },
      loadNotify() {
        this.$api(`message.opr.cnt`,{isRead: 1}).then(data => {
          this.notifies = parseInt(data)
        })
      },
      handleCommand(command) {
        switch (command) {
          case 'quit':
            removeToken();
            this.$router.replace({name: 'login'});
            this.$message.success('已退出')
            clearInterval(this.ts)
        }
      },
      handleMenu(module) {
        this.accountChildMenusSet(module)
        if (this.$route.name === 'home') {
          this.$router.push({name: module.modules[0].engName})
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .layout {
    display: flex;

    ::v-deep .el-dropdown-menu {
      .iconfont {
        font-size: 13px;
      }
    }

    &-main {
      flex: 1;
      width: 0;
      overflow-x: hidden;
      height: 100%;

      &-header {
        height: $header-height;
        position: absolute;
        background: #fff;
        z-index: 61;
        left: 0;
        right: 0;
        padding: 0 16px 0 80px;
        -webkit-box-shadow: inset 0 -1px 0 0 #e5ecf0;
        box-shadow: inset 0 -1px 0 0 #e5ecf0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;

        &-right {
          display: flex;
          align-items: center;

          .item {
            @include font-little();
            display: flex;
            align-content: center;
            align-items: center;

            i {
              margin-right: 2px;
            }
          }

          .item-margin {
            margin-right: $middle-space;
          }

          .item:hover {
            color: $theme-color;
            cursor: pointer;
          }

          .user {
            color: $theme-light-3;
            padding: 4px;
            width: 20px;
            text-align: center;
            border-radius: 50%;
            background-color: $theme-light-9;
          }

          .notify {
            height: 42px;
            line-height: 42px;
            text-align: center;

            .number {
              color: $brand-warning;
            }

            .bold {
              font-weight: 600;
            }

            .icon {

              cursor: pointer;

              .iconfont {
                font-size: 14px;
              }

              .share {
                display: inline-block;
                -webkit-transform-origin: center center;
                -ms-transform-origin: center center;
                transform-origin: center center;
                @include shake();
              }
            }
          }

          .notify:hover {
            .number {
              color: $theme-color;
            }
          }
        }
      }

      .margin-header {
        margin-top: $header-height;
        height: calc(100% - 52px);
      }

      .body-header {
        height: 100%;
      }

      &-body {
        display: flex;


        .normal-menu {
          width: 172px;
          padding: $middle-space $container-padding;
          padding-bottom: 0;
        }

        .collapse {
          width: 48px;
          padding-top: $container-padding;

          .main-menu-item {
            width: 48px;

            .item {
              text-align: center;
            }
          }
        }

        .main-menu {
          overflow-x: hidden;
          overflow-y: auto;
          position: relative;
          z-index: 2;
          flex-flow: column nowrap;
          align-items: stretch;
          flex: 0 0 auto;
          transition: all .5s;
          display: flex;
          flex-direction: column;
          border-right: 1px solid $content-border-color;

          &-item {
            flex: 1;
          }

          &-opr {
            height: 32px;
            line-height: 32px;
            text-align: center;
          }

          &-opr:hover {
            cursor: pointer;
            color: $theme-color;
          }
        }

        .main-container {
          width: 100%;
          overflow-y: auto;
          flex: 1 1 auto;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &-body {
            width: 100%;
            flex: 1 1 auto;
          }

        }
      }

      &-wrap {
        flex: 1;
      }
    }
  }
</style>
