<template>
  <div class="app-breadcrumb">
    <div class="main"><i class="iconfont icon-zhuye-"></i><span class="el-icon-arrow-right"></span></div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-for="(item)  in levelList">
        <el-breadcrumb-item :key="item.path" v-if="item.meta.title">
          <router-link :to="item.redirect||item.path">{{item.meta.title}}</router-link>
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        levelList: null
      }
    },
    computed: {
      ...mapGetters(['navMenus', `org`]),
    },
    methods: {
      /**
       * 生成面包屑的方法
       */
      getBreadcrumb() {
        let matched = this.$route.matched.filter((item) => {
          return item.meta.title ? item : null
        })
        this.levelList = matched || [];
        let title = []
        this.levelList.forEach((m) => {
          title.push(m.meta.title)
        })

        if (this.org) {
          document.title = title.reverse().join('-') + '-' + this.org.name
        }
      },
    },
    mounted() {
      this.getBreadcrumb();
    },
    watch: {
      $route() {
        this.getBreadcrumb();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-breadcrumb {
    display: flex;
    align-items: center;

    .main {
      display: flex;
      align-items: center;

      i {
        font-size: 1.25em;
      }

      span {
        display: inline-block;
        margin: 0 $small-space;
        font-size: 1em;
        color: #C0C4CC;
      }
    }

    .main:hover {
      i {
        color: $theme-color;
      }

      cursor: pointer;
    }
  }
</style>
