<template>
  <div>
    <template v-for="module in modules">
      <template v-if="!module.skipMenu">
        <div :key="module.uuid" v-if="!module.modules || module.modules.length === 0"
             :class="{
           active: $route.name.indexOf(module.engName) >= 0,
           parent:showIcon,
           center: collapse,
           }"
             class="item child" @click="handleMenu(module.engName)">
          <i class="iconfont" :class="module.iconFront" v-if="showIcon"></i><span v-if="!collapse">{{module.name}}</span>
        </div>
        <template v-else>
          <div :key="module.uuid + '-'"
               :class="{
                active: $route.name.indexOf(module.engName) >= 0,
                center: collapse,
           }"
               @click="handleClick(module)"
               class="item parent">
            <i class="iconfont" :class="module.iconFront" v-if="showIcon"></i><span
            v-if="!collapse">{{module.name}}</span>
          </div>
          <menu-item :modules="module.modules" :key="module.uuid + '---'" v-if="!collapse"></menu-item>
        </template>
      </template>

    </template>
  </div>
</template>


<script>
  export default {
    name: "menu-item",
    components: {},
    props: {
      modules: {
        Type: Array,
        default: function () {
          return []
        }
      },
      showIcon: {
        Type: Boolean,
        default: false
      },

      collapse: {
        Type: Boolean,
        default: false
      },
    },
    methods: {
      handleClick(module) {
        if (!this.collapse) {
          if (module.modules && module.modules.length > 0) {
            if (module.modules[0].skipMenu) {
              this.handleMenu(module.engName)
            } else {
              this.handleMenu(module.modules[0].engName)
            }
          }
        }

        this.$emit('update:collapse', false)
      },
      handleMenu(engName) {
        if (this.$route.name === engName) {
          return
        }
        if (this.$route.matched.length > 0) {
          if (this.$route.matched[this.$route.matched.length - 1].name.indexOf(engName) !== -1) {
            return
          }
        }

        this.$router.push({name: engName})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    padding: 12px 0;
    font-weight: 500;

    span {
      display: inline-block;
      margin-left: 40px;
    }
  }

  .child {
    padding: $small-space 0;
  }

  .parent {
    i {
      margin-right: $middle-space;
    }

    span {
      font-weight: 500;
      margin-left: 0;
    }
  }

  .center {
    text-align: center;

    i {
      margin: 0;
    }
  }

  .item:hover {
    cursor: pointer;
    color: $theme-color;
  }

  .active {
    color: $theme-color;
  }
</style>
