var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.modules),function(module){return [(!module.skipMenu)?[(!module.modules || module.modules.length === 0)?_c('div',{key:module.uuid,staticClass:"item child",class:{
         active: _vm.$route.name.indexOf(module.engName) >= 0,
         parent:_vm.showIcon,
         center: _vm.collapse,
         },on:{"click":function($event){return _vm.handleMenu(module.engName)}}},[(_vm.showIcon)?_c('i',{staticClass:"iconfont",class:module.iconFront}):_vm._e(),(!_vm.collapse)?_c('span',[_vm._v(_vm._s(module.name))]):_vm._e()]):[_c('div',{key:module.uuid + '-',staticClass:"item parent",class:{
              active: _vm.$route.name.indexOf(module.engName) >= 0,
              center: _vm.collapse,
         },on:{"click":function($event){return _vm.handleClick(module)}}},[(_vm.showIcon)?_c('i',{staticClass:"iconfont",class:module.iconFront}):_vm._e(),(!_vm.collapse)?_c('span',[_vm._v(_vm._s(module.name))]):_vm._e()]),(!_vm.collapse)?_c('menu-item',{key:module.uuid + '---',attrs:{"modules":module.modules}}):_vm._e()]]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }